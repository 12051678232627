var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"650px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.group.show),callback:function ($$v) {_vm.$set(_vm.group, "show", $$v)},expression:"group.show"}},[_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[(_vm.mode === 'list')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Equipos'))+" ")]):_vm._e(),(_vm.mode === 'create')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Crear equipo'))+" ")]):_vm._e(),(_vm.mode === 'update')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Actualizar equipo'))+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){return _vm.close()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-8"},[(_vm.mode === 'list')?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{'items-per-page-options': [5, 10, 15], 'disable-pagination': true, 'items-per-page-text': _vm.$t('Items por página') },"disable-pagination":"","headers":_vm.headers,"items":_vm.groups,"items-per-page":_vm.itemsPerPage,"loading-text":_vm.$t('Cargando datos...Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.'),"server-items-length":_vm.total,"page":_vm.page,"options":_vm.options},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.color_code",fn:function(ref){
var item = ref.item;
return [_c('v-sheet',{staticStyle:{"border-radius":"50%"},attrs:{"color":item.color_code,"elevation":"1","height":"25","width":"25"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$permissions.hasPermission('edit-teams'))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.viewGroup(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","left":""}},[_vm._v(" fas fa-user-edit ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Editar'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$permissions.hasPermission('delete-teams'))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0","color":"red lighten-2"},on:{"click":function($event){return _vm.deleteGroup(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","left":""}},[_vm._v(" far fa-trash-alt ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar'))+" ")])])]}}],null,false,4079429172)}),(_vm.groups.length)?_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",staticStyle:{"margin-top":"-52px"},attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"total-visible":5,"disabled":_vm.disabledPagination,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.mode === 'list')?_c('v-row',[_c('v-col',[(_vm.$permissions.hasPermission('edit-teams'))?_c('v-btn',{staticClass:"float-right px-6",attrs:{"elevation":"0","rounded":"","large":"","color":"light-green darken-2  white--text"},on:{"click":function($event){return _vm.showCreateGroupTeam()}}},[_vm._v(" "+_vm._s(_vm.$t('Crear equipo'))+" ")]):_vm._e(),_c('v-btn',{staticClass:"float-right",attrs:{"elevation":"0","rounded":"","large":"","text":"","color":"light-blue darken-2  white--text"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")])],1)],1):_vm._e(),(_vm.mode === 'create' || _vm.mode === 'update')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('validation-provider',{attrs:{"name":_vm.$t('nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-0",attrs:{"label":_vm.$t('Nombre'),"error-messages":errors,"maxlength":"64","counter":"","filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}})]}}],null,true)})],1),_c('v-col',[_c('v-color-picker',{attrs:{"width":"600px","hide-mode-switch":"","mode":"hexa"},on:{"update:color":_vm.colorChanged},model:{value:(_vm.group.colorCode),callback:function ($$v) {_vm.$set(_vm.group, "colorCode", $$v)},expression:"group.colorCode"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.mode === 'create')?_c('v-btn',{staticClass:"float-right px-6",attrs:{"disabled":invalid,"loading":_vm.loading,"elevation":"0","rounded":"","large":"","color":"light-green darken-2  white--text"},on:{"click":_vm.createGroup}},[_vm._v(" "+_vm._s(_vm.$t('Crear equipo'))+" ")]):_vm._e(),(_vm.mode === 'update')?_c('v-btn',{staticClass:"float-right px-6",attrs:{"disabled":invalid,"loading":_vm.loading,"rounded":"","elevation":"0","large":"","color":"light-green darken-2  white--text"},on:{"click":_vm.updateGroup}},[_vm._v(" "+_vm._s(_vm.$t('Actualizar equipo'))+" ")]):_vm._e(),_c('v-btn',{staticClass:"float-right",attrs:{"elevation":"0","rounded":"","large":"","text":"","color":"light-blue darken-2  white--text"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")])],1)],1)]}}],null,false,3534686612)})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }