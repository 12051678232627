export default class Agent {
  emails
  show

  constructor () {
    this.emails = []
    this.show = false
  }

  reset () {
    this.emails = []
    this.show = false
  }
}
