import EventBus from '@/util/EventBus.js'

export default {
  name: 'CreateAgent',
  props: {
    agent: {
      type: Object,
      require: true,
    },
  },
  components: {},
  data: function () {
    return {
      loading: false,
      defaultEmails: [],
      searchEmails: null,
      selectedRole: 'admin',
    }
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  methods: {
    close () {
      this.agent.reset()
    },
    createAgent () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.close()
        EventBus.$emit('showAlert', 'success', this.$t('Agente creado correctamente'))
      }, 1000)
    },
    removeEmail (removeEmail) {
      this.agent.emails = this.agent.emails.filter(function (email) {
        return removeEmail !== email
      })
    },
  },
}
