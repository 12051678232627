export default class Group {
  id
  name
  colorCode
  show

  constructor () {
    this.id = null
    this.name = ''
    this.colorCode = 'ffffff'
    this.show = false
  }

  load (data) {
    this.id = data.id
    this.name = data.name
    this.colorCode = data.color
  }

  reset () {
    this.id = null
    this.name = ''
    this.colorCode = 'ffffff'
  }
}
