import { Service } from './service'

const PATH = 'teams'

class TeamService extends Service {
  findAll (params = {}) {
    return this.get(PATH + '/index', params)
  }

  find (params = {}) {
    return this.get(PATH + '/all', params)
  }

  create (params = {}) {
    return this.post(PATH + '/create', params)
  }

  update (params = {}) {
    return this.post(PATH + '/update', params)
  }

  delete (params = {}) {
    return this.post(PATH + '/delete', params)
  }
}

export default new TeamService()
