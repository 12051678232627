<template>
  <div>
    <header-top-dashboard neutral />
    <v-container
      fluid
      class="pt-0 px-9 mt-n16"
    >
      <v-row class="px-1 pt-0">
        <v-col
          cols="12"
          class="mt-n3"
        >
          <team />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
  import Team from '../../../components/Team/Team.vue'

  export default {
    name: 'TeamPage',
    components: {
      HeaderTopDashboard,
      Team,
    },
  }
</script>

<style scoped>

</style>
