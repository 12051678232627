import Group from '../../models/team/Group'
import Agent from '../../models/team/Agent'
import TeamService from '../../services/teams.service'

import GroupTeam from './Group/GroupTeam.vue'
import CreateAgent from './Agent/CreateAgent.vue'

export default {
  name: 'Team',
  props: {},
  components: {
    GroupTeam,
    CreateAgent,
  },
  data: function () {
    return {
      teams: [],
      groups: [],
      group: new Group(),
      agent: new Agent(),
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
    }
  },
  created () {},
  mounted () {
    this.getGroups()
  },
  watch: {},
  computed: {
    headers () {
      return [
        {
          text: this.$t('Nombre y Apellido'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('Email'),
          value: 'email',
          sortable: false,
        },
        {
          text: this.$t('Rol'),
          value: 'role',
          sortable: false,
        },
        {
          text: this.$t('Equipo'),
          value: 'equipo',
          sortable: false,
        },
        {
          text: this.$t('Estado'),
          value: 'estado',
          sortable: false,
        },
        {
          text: this.$t('Validación'),
          value: 'validate',
          sortable: false,
        },
        {
          text: this.$t('Última conexión'),
          value: 'dateLast',
          sortable: false,
        },
        {
          text: this.$t('Acciones'),
          value: 'action',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    openGroupTeam () {
      this.group.show = true
    },
    openCreateAgent () {
      this.agent.show = true
    },
    getGroups () {
      TeamService.find()
      .then(
        (response) => {
          this.groups = response.data
        },
      )
    },
  },
}
