import EventBus from '@/util/EventBus.js'
import TeamService from '../../../services/teams.service'

export default {
  name: 'GroupTeam',
  props: {
    group: {
      type: Object,
      require: true,
    },
  },
  components: {},
  data: function () {
    return {
      groups: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      mode: 'list',
      loading: false,
      tableAction: '',
    }
  },
  created () {},
  mounted () {
    this.getGroups()
  },
  watch: {
    params: {
      handler () {
        this.getGroups()
      },
      deep: true,
    },
  },
  computed: {
    params (nv) {
      return {
        ...this.options,
      }
    },
    headers () {
      const headers = [
        {
          text: this.$t('Nombre'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('Color'),
          value: 'color_code',
          sortable: false,
        },
      ]

      if (this.$permissions.hasPermission('edit-teams')) {
        headers.push({
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        })
      }
      return headers
    },
  },
  methods: {
    close () {
      if (this.mode === 'list') {
        this.group.reset()
        this.mode = 'list'
        this.group.show = false
      }

      if (this.mode === 'create' || this.mode === 'update') {
        this.group.reset()
        this.mode = 'list'
      }
    },
    showCreateGroupTeam () {
      this.mode = 'create'
    },
    colorChanged (color) {
      this.group.colorCode = color.hexa
    },
    createGroup () {
      this.loading = true
      TeamService.create({
        name: this.group.name,
        color_code: this.group.colorCode,
      })
      .then(
        () => {
          this.getGroups()
          this.group.reset()
          this.mode = 'list'
          EventBus.$emit('showAlert', 'success', this.$t('Equipo creado correctamente'))
        },
        () => {
          EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar crear el equipo'))
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    updateGroup () {
      this.loading = true
      TeamService.update({
        id: this.group.id,
        name: this.group.name,
        color_code: this.group.colorCode,
      })
      .then(
        () => {
          this.getGroups()
          this.group.reset()
          this.mode = 'list'
          EventBus.$emit('showAlert', 'success', this.$t('Equipo actualizado correctamente'))
        },
        () => {
          EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar actualizar el equipo'))
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    getParams () {
      const page = this.tableAction === 'DELETED' && this.groups.length === 1 && this.options.page > 1
        ? this.options.page - 1
        : this.options.page
      this.tableAction = null
      const params = {
        page: page || this.page,
        per_page: this.options.itemsPerPage ?? this.itemsPerPage,
      }
      return params
    },
    getGroups () {
      this.notes = []
      this.mode = 'list'
      const data = this.getParams()
      TeamService.findAll(data)
        .then(
          (response) => {
            this.groups = response.data.data
            this.total = response.data.total
            this.pageCount = response.data.last_page
            this.page = response.data.current_page
            this.disabledPagination = false
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar ver las notas'))
          },
        )
    },
    viewGroup (item) {
      this.mode = 'update'
      this.group.id = item.id
      this.group.name = item.name
      this.group.colorCode = item.color_code
    },
    deleteGroup (item) {
      TeamService.delete({
        id: item.id,
      })
      .then(
        () => {
          this.tableAction = 'DELETED'
          this.getGroups()
          EventBus.$emit('showAlert', 'success', this.$t('Equipo eliminado correctamente'))
        },
        () => {
          EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar eliminar la nota'))
        },
      )
    },
  },
}
